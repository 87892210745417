<template>
  <v-row justify="center">
  <v-dialog
    persistent
    v-model="isEditsPhotoDialogBox"
    max-width="900"
    v-resize="onResize"
  >
    <v-card class="vCard" :style="{ height: vCardHeight }">
      <v-card-title primary-title>
        <v-flex class="align-center">
          <span>{{ title }}</span>
        </v-flex>
      </v-card-title>
      <v-card-text
        class="align-center"
        ref="heremap"
        style="height:80%"
        id="vCardText"
      >
        <v-progress-linear
          v-if="isFetchingAttchment"
          indeterminate
          rounded
          height="2"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="downloadEditsPhoto" :disabled="isFetchingAttchment">{{ $t("Download") }}</v-btn>
        <v-btn text @click="closeEditsPhoto">{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-row>
</template>

<script>
import HereUtil from "@/util/here";
import H from "@here/maps-api-for-javascript/bin/mapsjs.bundle";
import editsApi from "@/api/edit";
import { mapState } from "vuex";

export default {
  name: "editsPhoto",
  data() {
    return {
      isEditsPhotoDialogBox: true,
      hereUtil: new HereUtil(),
      vCardHeight: undefined,
      isFetchingAttchment: true,
      title: "Photo",
      mapForEditsPhoto: undefined
    };
  },
    computed: {
    ...mapState({
      currentSelectedEditFeature: state => state.edit.currentSelectedEditFeature,
      currentFeatureInfo: state => state.featurePanel.currentFeatureInfo,
      editFillColorForAudioAndPhotoIcons: state => state.edit.editFillColorForAudioAndPhotoIcons
    })
  },
  created() {
    let currentSelectedEditFeature = this.currentSelectedEditFeature;
    this.title = currentSelectedEditFeature.title;
    //get photo attchment and display
    editsApi.getEditFeatureAttachment(currentSelectedEditFeature.parentId, currentSelectedEditFeature.childId).then(res => {
        this.isFetchingAttchment = false;
        let image = document.createElement("img");
        image.src = res;

        let aspectRatio = image.width / image.height;
        let boundingBoxHeight = -6 / aspectRatio;
        if (!boundingBoxHeight) {
          boundingBoxHeight = -4;
        }

        let mapTypes = this.hereUtil.platform.createDefaultLayers();
        this.mapForEditsPhoto = new H.Map(
          this.$refs.heremap,
          mapTypes.vector.normal.map,
          { pixelRatio: 1 }
        );

        let mapBehavior = new H.mapevents.Behavior(
          new H.mapevents.MapEvents(this.mapForEditsPhoto)
        );
        this.setBlankTileAsBaseMap(this.mapForEditsPhoto, 7, 8);

        let boundsRect = new H.geo.Rect(0, 0, boundingBoxHeight, 6);
        let overlay = new H.map.Overlay(boundsRect, image);
        this.mapForEditsPhoto.addObject(overlay);
        this.mapForEditsPhoto.getViewModel().setLookAtData({
          bounds: boundsRect
        });
        this.restrictBounds(this.mapForEditsPhoto, boundsRect);
    });
  },
  methods: {
    onResize() {
      this.vCardHeight = window.innerHeight * 0.9 + "px";
    },
    closeEditsPhoto() {
      if (this.currentFeatureInfo.type === "multiEditFeatures") {
        this.$store.commit("commitAppMode", "featurePanel");
      } else {
        this.$store.commit("commitAppMode", "edit");
      }
      let ClonedElement = this.currentSelectedEditFeature.clonedElement;
      ClonedElement.firstElementChild.style.fill = this.editFillColorForAudioAndPhotoIcons;
      ClonedElement.style.zIndex = "1";
      this.$store.commit("edit/changeSelectedFeature", ClonedElement);
    },
    setBlankTileAsBaseMap(map, minZoom, maxZoom) {
      let whiteTileProvider = new H.map.provider.ImageTileProvider({
        min: minZoom,
        max: maxZoom,
        getURL: (column, row, zoom) => {}
      });

      let whiteTileLayer = new H.map.layer.TileLayer(whiteTileProvider);
      map.setBaseLayer(whiteTileLayer);
    },
    restrictBounds(map, bounds) {
      map.getViewModel().addEventListener("sync", () => {
        var center = map.getCenter();
        if (!bounds.containsPoint(center)) {
          if (center.lat > bounds.getTop()) {
            center.lat = bounds.getTop();
          } else if (center.lat < bounds.getBottom()) {
            center.lat = bounds.getBottom();
          }
          if (center.lng < bounds.getLeft()) {
            center.lng = bounds.getLeft();
          } else if (center.lng > bounds.getRight()) {
            center.lng = bounds.getRight();
          }
          map.setCenter(center);
        }
      });
    },
    downloadEditsPhoto() {
      let self = this;
      this.mapForEditsPhoto.capture(canvas => {
        let downloadName = self.title + ".png";
        let a = document.createElement("a");
        a.setAttribute("href", canvas.toDataURL());
        a.setAttribute("download", downloadName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
  }
};
</script>

<style>
.align-center {
  align-items: center !important;
  text-align: center;
  display: inline-block;
}
#vCardText canvas {
  top: -100px !important;
  left: -200px !important;
  position: initial !important;
}
#vCardText .H_imprint {
  visibility: hidden;
}
</style>
